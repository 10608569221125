import * as React from "react";
import styled from "styled-components";


const Container = styled.div`
position: relative;
background-image: url(${p=>p.image});
background-size: cover;
display: flex;
flex-direction: column;

width: 280px;
height:340px;
z-index: 0;

@media (max-width: 900px) {

  width:calc(50vw - 38px);
  height:auto ;
  aspect-ratio:0.82 ;
}
`

const Overlay = styled.div`
position: absolute;
background-color: rgba(var(--moradorgb),0.85) ;
z-index: 1;
width:100%;
height: 100%;
display:none;

${Container}:hover &{
  display :block ;
}

`
const Overlayblack = styled.div`
position: absolute;
width: 100%;
height: 30%;
bottom:0px;
background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
${Container}:hover &{
  display :none ;
}

`



const Nombre = styled.div`
font-weight:800 ;
color: var(--blanco);
position: absolute;
bottom:8px ;
margin: 0px 20px ;

${Container}:hover &{
  z-index:2 ;
  bottom:121px;
}

@media (max-width: 900px){
  font-size:10px ;
}

`

const Puesto = styled.div`
color: var(--blanco);
display: none;
position: absolute;
margin: 0px 20px ;
${Container}:hover &{
  display :block ;
  z-index:2;
  bottom:72px ;
}

@media (max-width: 900px){
  font-size:10px ;
}

`
const Icono = styled.a`
position:absolute ;
width: 32px;
height: 32px;
left:20px ;
background-color:#537EEC;
border-radius:4px ;



display: none;
${Container}:hover &{
  display :block ;
  z-index:1;
  bottom:20px ;
  display: flex;
  justify-content:center ;
align-items: center;
}
`



const Idcard= (props) => {

    return(
     
        <Container image={props.image} >
      <Overlay>  </Overlay>
      <Overlayblack>  </Overlayblack>
      
<Nombre>
{props.name}
</Nombre>
<Puesto>
    {props.puesto}
</Puesto>
<Icono href={props.enlace}>
<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><rect x="0" y="0" width="24" height="24" fill="none" stroke="none" /><path fill="white" fillRule="evenodd" d="M9.429 8.969h3.714v1.85c.535-1.064 1.907-2.02 3.968-2.02c3.951 0 4.889 2.118 4.889 6.004V22h-4v-6.312c0-2.213-.535-3.461-1.897-3.461c-1.889 0-2.674 1.345-2.674 3.46V22h-4V8.969ZM2.57 21.83h4V8.799h-4V21.83ZM7.143 4.55a2.53 2.53 0 0 1-.753 1.802a2.573 2.573 0 0 1-1.82.748a2.59 2.59 0 0 1-1.818-.747A2.548 2.548 0 0 1 2 4.55c0-.677.27-1.325.753-1.803A2.583 2.583 0 0 1 4.571 2c.682 0 1.336.269 1.819.747c.482.478.753 1.126.753 1.803Z" clip-rule="evenodd"/></svg>
</Icono>
    
    </Container>
    )
    }
    export default Idcard;